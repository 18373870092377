import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaHome, FaUserGraduate, FaUserTie, FaSchool, FaChartLine, FaMoneyBillWave,
  FaFileAlt, FaBullhorn, FaCog, FaSignOutAlt, FaClock, FaChevronDown, FaChevronUp
} from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import logo from '../assets/logo.png'

const Sidebar = () => {
  const { logout } = useContext(AuthContext);
  const [showTimetablesDropdown, setShowTimetablesDropdown] = useState(false);
  const [showEmployeesDropdown, setShowEmployeesDropdown] = useState(false);
  const [showFinancialsDropdown, setShowFinancialsDropdown] = useState(false);
  const [showClassesDropdown, setShowClassesDropdown] = useState(false);
  const [showStudentsDropdown, setShowStudentsDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false); // State for Settings dropdown

  const toggleTimetablesDropdown = () => {
    setShowTimetablesDropdown(!showTimetablesDropdown);
  };

  const toggleEmployeesDropdown = () => {
    setShowEmployeesDropdown(!showEmployeesDropdown);
  };

  const toggleFinancialsDropdown = () => {
    setShowFinancialsDropdown(!showFinancialsDropdown);
  };

  const toggleClassesDropdown = () => {
    setShowClassesDropdown(!showClassesDropdown);
  };

  const toggleStudentsDropdown = () => {
    setShowStudentsDropdown(!showStudentsDropdown);
  };

  const toggleSettingsDropdown = () => {
    setShowSettingsDropdown(!showSettingsDropdown); // Toggle Settings dropdown
  };

  return (
    <div className="h-screen w-64 bg-pink-700 text-white fixed top-0 left-0 overflow-y-auto">
      <div className="p-4 text-2xl w-full font-bold flex justify-center items-center">
        <img src={logo} alt="Logo" className="w-20 h-15" />
      </div>

      <nav className="mt-10">
        <ul>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaHome className="mr-2" /><Link to="/dashboard">Home</Link>
          </li>

          {/* Students Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleStudentsDropdown}>
            <FaUserGraduate className="mr-2" />
            <span>Students</span>
            {showStudentsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showStudentsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/students">All Students</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/new-students">New Students</Link>
              </li>
            </ul>
          )}

          {/* Employees Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleEmployeesDropdown}>
            <FaUserTie className="mr-2" />
            <span>Employees</span>
            {showEmployeesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showEmployeesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/employees">View Employees</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/create-employee">Add Employee</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/create-payslip">Payslips</Link>
              </li>
            </ul>
          )}

          {/* Classes Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleClassesDropdown}>
            <FaSchool className="mr-2" />
            <span>Classes</span>
            {showClassesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showClassesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/classes">View Classes</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/add-gradelevelclass">Add Form Level Class</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/add-subjectlevelclass">Add Subject Level Class</Link>
              </li>
            </ul>
          )}

          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaChartLine className="mr-2" /><Link to="/results">Results</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaChartLine className="mr-2" /><Link to="/scheme-books">Scheme Books</Link>
          </li>
          {/* Financials Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleFinancialsDropdown}>
            <FaMoneyBillWave className="mr-2" />
            <span>Financials</span>
            {showFinancialsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showFinancialsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/create-invoice">Link Kahwani</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/create-invoice">Create Invoice</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/view-invoices">View Invoices</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/make-payment">Make Payment</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/payments">Payments</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/student-records">Student Records</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/temp-payments">Pending Transactions</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/update-balance">Manual Balance Update</Link>
              </li>
            </ul>
          )}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaFileAlt className="mr-2" /><Link to="/assessments">Assessments</Link>
          </li>

          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaBullhorn className="mr-2" /><Link to="/announcements">Announcements</Link>
          </li>
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaSchool className="mr-2" /><Link to="/lesson-plans">Lesson Plans</Link>
          </li>
          {/* Timetables Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleTimetablesDropdown}>
            <FaClock className="mr-2" />
            <span>Time Tables</span>
            {showTimetablesDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showTimetablesDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/add-timetable">Add Timetable</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/edit-timetable">Edit Timetable</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/view-timetable">View Timetable</Link>
              </li>
            </ul>
          )}

          {/* Settings Dropdown */}
          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600 cursor-pointer" onClick={toggleSettingsDropdown}>
            <FaCog className="mr-2" />
            <span>Settings</span>
            {showSettingsDropdown ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
          </li>
          {showSettingsDropdown && (
            <ul className="ml-8">
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/settings">General Settings</Link>
              </li>
              <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
                <Link to="/registration-fees">Registration Fees</Link>
              </li>
            </ul>
          )}

          <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
            <FaSignOutAlt className="mr-2" /><button onClick={logout}>Logout</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
