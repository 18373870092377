import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';

function StudentDebtors() {
  const [debtors, setDebtors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [term, setTerm] = useState('');
  const [form, setForm] = useState('');
  const [regNumber, setRegNumber] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addYear, setAddYear] = useState(new Date().getFullYear());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addTerm, setAddTerm] = useState('');
  const [isAddingDebtor, setIsAddingDebtor] = useState(false);
  const [isRemovingDebtor, setIsRemovingDebtor] = useState(null);

  useEffect(() => {
    if (!isSearching) {
      fetchDebtors();
    }
  }, [page, isSearching]);

  const fetchDebtors = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/student-debtors?page=${page}&limit=100`);
      setDebtors(response.data.debtors);
      setTotalPages(response.data.pagination.pages);
    } catch (error) {
      console.error('Error fetching debtors:', error);
      alert('Failed to fetch debtors list');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setIsSearching(false);
      return;
    }

    try {
      setLoading(true);
      setIsSearching(true);
      const response = await axios.get(`${BASE_URL}/student-debtors/search?reg_number=${searchQuery}`);
      setDebtors(response.data.debtors);
    } catch (error) {
      console.error('Error searching debtors:', error);
      alert('Failed to search debtors');
    } finally {
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    setIsSearching(false);
    setPage(1);
  };

  const handleAddDebtor = async () => {
    try {
      if (!addTerm) {
        alert('Please select a term');
        return;
      }

      setIsAddingDebtor(true);
      await axios.post(`${BASE_URL}/student-debtors/add`, {
        reg_number: regNumber,
        year: addYear,
        term: addTerm,
        form
      });
      alert('Student added to debtors list successfully');
      setIsAddModalOpen(false);
      resetForm();
      fetchDebtors();
    } catch (error) {
      console.error('Error adding debtor:', error);
      alert(error.response?.data?.message || 'Failed to add student to debtors list');
    } finally {
      setIsAddingDebtor(false);
    }
  };

  const handleRemoveDebtor = async (debtor) => {
    if (window.confirm('Are you sure you want to remove this student from the debtors list?')) {
      try {
        setIsRemovingDebtor(debtor.reg_number);
        await axios.post(`${BASE_URL}/student-debtors/remove`, {
          reg_number: debtor.reg_number,
          year: debtor.year,
          term: debtor.term,
          form: debtor.form
        });
        alert('Student removed from debtors list successfully');
        fetchDebtors();
      } catch (error) {
        console.error('Error removing debtor:', error);
        alert('Failed to remove student from debtors list');
      } finally {
        setIsRemovingDebtor(null);
      }
    }
  };

  const resetForm = () => {
    setRegNumber('');
    setForm('');
    setAddYear(new Date().getFullYear());
    setAddTerm('');
  };

  const openAddModal = () => {
    resetForm();
    setIsAddModalOpen(true);
  };

  return (
    <div className="p-4">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-4">Students with Arrears</h1>
        <div className="flex gap-4 mb-4">
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Year"
            className="border rounded px-4 py-2"
          />
          <select
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            className="border rounded px-4 py-2"
          >
            <option value="">Select Term</option>
            <option value="1">Term 1</option>
            <option value="2">Term 2</option>
            <option value="3">Term 3</option>
          </select>
          <button
            onClick={openAddModal}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Student
          </button>
          <div className="flex gap-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by Reg Number"
              className="border rounded px-4 py-2"
            />
            <button
              onClick={handleSearch}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Search
            </button>
            {isSearching && (
              <button
                onClick={clearSearch}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Clear
              </button>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-pink-600"></div>
        </div>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Reg Number
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Form
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Year
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Term
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {debtors.map((debtor) => (
              <tr key={debtor.id} className="bg-pink-50">
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {debtor.reg_number}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {debtor.Name} {debtor.Surname}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {debtor.form}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {debtor.year}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {debtor.term}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <button
                    onClick={() => handleRemoveDebtor(debtor)}
                    disabled={isRemovingDebtor === debtor.reg_number}
                    className={`bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center min-w-[100px]`}
                  >
                    {isRemovingDebtor === debtor.reg_number ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
                    ) : (
                      'Remove'
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!isSearching && totalPages > 1 && (
        <div className="mt-4 flex justify-center gap-2">
          <button
            onClick={() => setPage(p => Math.max(1, p - 1))}
            disabled={page === 1}
            className="bg-pink-600 text-white px-4 py-2 rounded disabled:bg-gray-400"
          >
            Previous
          </button>
          <span className="px-4 py-2">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => setPage(p => Math.min(totalPages, p + 1))}
            disabled={page === totalPages}
            className="bg-pink-600 text-white px-4 py-2 rounded disabled:bg-gray-400"
          >
            Next
          </button>
        </div>
      )}

      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Add Student to Debtors List</h2>
            <div className="space-y-4">
              <input
                type="text"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                placeholder="Registration Number"
                className="border rounded px-4 py-2 w-full"
              />
              <input
                type="text"
                value={form}
                onChange={(e) => setForm(e.target.value)}
                placeholder="Form"
                className="border rounded px-4 py-2 w-full"
              />
              <input
                type="number"
                value={addYear}
                onChange={(e) => setAddYear(e.target.value)}
                placeholder="Year"
                className="border rounded px-4 py-2 w-full"
              />
              <select
                value={addTerm}
                onChange={(e) => setAddTerm(e.target.value)}
                className="border rounded px-4 py-2 w-full"
              >
                <option value="">Select Term</option>
                <option value="1">Term 1</option>
                <option value="2">Term 2</option>
                <option value="3">Term 3</option>
              </select>
              <div className="flex justify-end gap-2">
                <button
                  onClick={handleAddDebtor}
                  disabled={isAddingDebtor}
                  className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center min-w-[60px]`}
                >
                  {isAddingDebtor ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
                  ) : (
                    'Add'
                  )}
                </button>
                <button
                  onClick={() => setIsAddModalOpen(false)}
                  disabled={isAddingDebtor}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentDebtors;
